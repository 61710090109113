
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';
import DataLoading from '@/lib/mixins/dataLoading';
import proceduresModule from '@/store/modules/proceduresModule';
import workflowModule from '@/store/modules/workflowModule';
import { DateTime } from 'luxon';
import accountModule from '@/store/modules/accountModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    UpdateProcedure: () => getComponent('procedures/UpdateProcedure'),
    ProcedureItem: () => getComponent('procedures/ProcedureItem'),
    UpdateWorkflow: () => getComponent('workflows/UpdateWorkflow'),
    WorkflowItem: () => getComponent('workflows/WorkflowItem'),
  },
})
export default class Workflow extends mixins(DataLoading) {
  dataLoading = false;

  showProcedures = true;

  showWorkflows = true;

  activeLeftComponent = '';

  isLoadingProcedureID = '';

  get procedures() {
    return proceduresModule.procedures;
  }

  get workflows() {
    return workflowModule.workflows;
  }

  async created() {
	  workflowModule.listTeams({useExisting: true})
    this.dataLoading = true;
    await accountModule.getReassignmentList({useExisting: true});
    proceduresModule.getProcedures();
    workflowModule.getWorkflows();
    this.dataLoading = false;
  }

  toggleProcedures() {
    this.showProcedures = !this.showProcedures;
  }

  toggleWorkflows() {
    this.showWorkflows = !this.showWorkflows;
  }

  getWorkflowTime(time) {
    return DateTime.fromISO(time).toFormat('LL/dd ha');
  }

  showWorkflowItem(workflow) {
    workflowModule.setActiveWorkflow(workflow);
    this.$router.push({
      name: 'Workflow',
      params: {
        activeEntity: 'workflow',
        id: workflow.id,
      },
    });
    this.activeLeftComponent = 'workflow-item';
    workflowModule.getActiveWorkflow(workflow);
  }

  async showProcedureItem(procedure) {
	  this.closeComponent();
	  this.isLoadingProcedureID = procedure.id;
    this.$router.push({
      name: 'Workflow',
      params: {
        activeEntity: 'procedure',
        id: procedure.id,
      },
    });
    // this.activeLeftComponent = 'procedure-item';
    await proceduresModule.setActiveProcedure(procedure);
    await proceduresModule.getActiveProcedure(procedure);
    this.activeLeftComponent = 'procedure-item';
    this.isLoadingProcedureID = '';
  }

  async reloadPage() {
	  await proceduresModule.getProcedures();
    this.activeLeftComponent = '';

    this.$router.push({
      name: 'Workflow',
    });
    this.activeLeftComponent = 'update-workflow'
  }

  addWorkflow() {
    this.$router.push({
      name: 'Workflow',
    });
    this.activeLeftComponent = 'update-workflow';
  }

  closeComponent() {
    this.activeLeftComponent = '';

    this.$router.push({
      name: 'Workflow',
    });
  }
}
